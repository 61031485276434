import {Component} from "react";

class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const contentful = require("contentful");
    const client = contentful.createClient({
      // This is the space ID. A space is like a project folder in Contentful terms
      space: "ri2478dp9tc5",
      // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      accessToken: "-vmJagUp44O0Nn7iYzeJ6bqXsJiiA0G7WOXRBFZj2Ak"
    });
    client
        .getAsset("3whBlhGzvCwHOYHGDvTIQm")
        .then(asset => {
          this.setState({
            asset: asset
          })
        })
        .catch(err => console.log(err))
  }

  render() {
    return (
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
          <div className='container'>
            <div className='navbar-header'>
              <button
                  type='button'
                  className='navbar-toggle collapsed'
                  data-toggle='collapse'
                  data-target='#bs-example-navbar-collapse-1'
              >
                {' '}
                <span className='sr-only'>Toggle navigation</span>{' '}
                <span className='icon-bar'></span>{' '}
                <span className='icon-bar'></span>{' '}
                <span className='icon-bar'></span>{' '}
              </button>
              <a className='navbar-brand page-scroll' href='#page-top'>
                <img src="img/web-logo.png" alt='Suonenjoen Palveluhakemisto 2022-2024'/>
              </a>{' '}
            </div>

            <div
                className='collapse navbar-collapse'
                id='bs-example-navbar-collapse-1'
            >
              <ul className='nav navbar-nav navbar-right'>
                <li>
                  <a href='#portfolio' className='page-scroll'>
                    Yritykset
                  </a>
                </li>
                <li>
                  <a href='#search' className='page-scroll'>
                    Haku
                  </a>
                </li>
                <li>
                  <a href='#services' className='page-scroll'>
                    Tietoja
                  </a>
                </li>
                <li>
                  {this.state.asset ?
                      <a href={this.state.asset.fields.file.url}>Lataa PDF</a>
                      : '' // TODO this causes the front page links to flicker for a couple of seconds...
                      }
                </li>
              </ul>
            </div>
          </div>
        </nav>
    )
  }
}

export default Navigation;
