import {Image} from "./image"
import {Component} from "react"

class Gallery extends Component {

    tier1Count = 2;
    tier2Count = 3;
    tier3Count = 8;

    timer;

    roughTotalAdPageCount = 8 // this is a complete magic number, should probably be calculated

    constructor(props) {
        super(props)
        this.state = {}

        this.isLastPage = this.isLastPage.bind(this)
        this.isFirstPage = this.isFirstPage.bind(this)
        this.onClickCompaniesRight = this.onClickCompaniesRight.bind(this)
        this.onClickCompaniesLeft = this.onClickCompaniesLeft.bind(this)
    }

    componentDidMount() {

        const contentful = require("contentful");
        const client = contentful.createClient({
            // This is the space ID. A space is like a project folder in Contentful terms
            space: "ri2478dp9tc5", // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
            accessToken: "-vmJagUp44O0Nn7iYzeJ6bqXsJiiA0G7WOXRBFZj2Ak"
        });

        const randomizedIndex = Math.random() * this.roughTotalAdPageCount

        client
            .getEntries({
                content_type: 'company',
                order: 'fields.order', 'fields.tier': 1
            })
            .then(entries => {
                this.setState({
                    items_tier_1_full: entries.items,
                    items_tier_1: entries.items.slice(0, this.tier1Count),
                    items_tier_1_index: 0
                })
                this.updateTier1Index(this.state.items_tier_1_full, randomizedIndex)
            })
            .catch(err => console.log(err))
        client
            .getEntries({
                content_type: 'company',
                order: 'fields.order', 'fields.tier': 2
            })
            .then(entries => {
                this.setState({
                    items_tier_2_full: entries.items,
                    items_tier_2: entries.items.slice(0, this.tier2Count),
                    items_tier_2_index: 0
                })
                this.updateTier2Index(this.state.items_tier_2_full, randomizedIndex)
            })
            .catch(err => console.log(err))
        client
            .getEntries({
                content_type: 'company',
                order: 'fields.order', 'fields.tier': 3
            })
            .then(entries => {
                this.setState({
                    items_tier_3_full: entries.items,
                    items_tier_3: entries.items.slice(0, this.tier3Count),
                    items_tier_3_index: 0
                })
                this.updateTier3Index(this.state.items_tier_3_full, randomizedIndex)
            })
            .catch(err => console.log(err))

        this.timer = setInterval(this.onClickCompaniesRight.bind(this), 20000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    onClickCompaniesRight() {

        // clear timer each time reload is pressed or called
        clearInterval(this.timer)
        this.timer = setInterval(this.onClickCompaniesRight.bind(this), 30000)

        let i = this.state.items_tier_3_index
        i += this.tier3Count
        if (i > this.state.items_tier_3_full.length - this.tier3Count) {
            i = this.state.items_tier_3_full.length - this.tier3Count;
        }
        this.updateTier3Index(this.state.items_tier_3_full, i)

        i = this.state.items_tier_2_index
        i += this.tier2Count
        if (i > this.state.items_tier_2_full.length - this.tier2Count) {
            i = this.state.items_tier_2_full.length - this.tier2Count;
        }
        this.updateTier2Index(this.state.items_tier_2_full, i)

        i = this.state.items_tier_1_index
        i += this.tier1Count
        if (i > this.state.items_tier_1_full.length - this.tier1Count) {
            i = this.state.items_tier_1_full.length - this.tier1Count;
        }
        this.updateTier1Index(this.state.items_tier_1_full, i)
    }

    onClickCompaniesLeft() {

        // clear timer each time reload is pressed or called
        clearInterval(this.timer)
        this.timer = setInterval(this.onClickCompaniesRight.bind(this), 30000)

        let i = this.state.items_tier_3_index
        i -= this.tier3Count
        if (i < 0) {
            i = 0;
        }
        this.updateTier3Index(this.state.items_tier_3_full, i)

        i = this.state.items_tier_2_index
        i -= this.tier2Count
        if (i < 0) {
            i = 0;
        }
        this.updateTier2Index(this.state.items_tier_2_full, i)

        i = this.state.items_tier_1_index
        i -= this.tier1Count
        if (i < 0) {
            i = 0;
        }
        this.updateTier1Index(this.state.items_tier_1_full, i)

    }

    updateTier3Index(items, i) {
        this.setState({
            items_tier_3_index: i, items_tier_3: items.slice(i, i + this.tier3Count)
        })
    }

    updateTier2Index(items, i) {
        this.setState({
            items_tier_2_index: i, items_tier_2: items.slice(i, i + this.tier2Count)
        })
    }

    updateTier1Index(items, i) {
        this.setState({
            items_tier_1_index: i, items_tier_1: items.slice(i, i + this.tier1Count)
        })
    }

    isFirstPage() {
        return this.state.items_tier_1_index === 0 && this.state.items_tier_2_index === 0 && this.state.items_tier_3_index === 0
    }

    isLastPage() {
        return this.state.items_tier_1_index >= this.state.items_tier_1_full?.length - this.tier1Count && this.state.items_tier_2_index >= this.state.items_tier_2_full?.length - this.tier2Count && this.state.items_tier_3_index >= this.state.items_tier_3_full?.length - this.tier3Count
    }

    render() {
        return (<div id='portfolio' className='text-center'>
            <div className='container'>
                <div className='row' style={{float: 'left', display: 'inline', width: '100%'}}>
                    <div className='section-title'>
                        <h2>Yritykset</h2>
                    </div>
                    <div className='row'>
                        {this.isFirstPage(this) ? ('') : (<button onClick={this.onClickCompaniesLeft}
                                                                  className="btn btn-custom">Edellinen</button>)}
                        {this.isLastPage(this) ? ('') : (<button onClick={this.onClickCompaniesRight}
                                                                 className="btn btn-custom">Seuraava
                        </button>)}

                    </div>
                    <div className='row'>
                        <div className='portfolio-items'>
                            {this.state.items_tier_1 ? this.state.items_tier_1.map((d) => (
                                <div key={`${d.fields.name}`} className='col-sm-6 col-md-6 col-lg-6'>
                                    <Image item={d} thumbnailImage={"img/portfolio/01-large.jpg"}/>
                                </div>)) : 'Ladataan...'}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='portfolio-items'>
                            {this.state.items_tier_2 ? this.state.items_tier_2.map((d) => (
                                <div key={`${d.fields.name}`} className='col-sm-4 col-md-4 col-lg-4'>
                                    <Image item={d} thumbnailImage={"img/portfolio/01-large.jpg"}/>
                                </div>)) : 'Ladataan...'}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='portfolio-items'>
                            {this.state.items_tier_3 ? this.state.items_tier_3.map((d) => (
                                <div key={`${d.fields.name}`} className='col-sm-3 col-md-3 col-lg-3'>
                                    <Image item={d} thumbnailImage={"img/portfolio/01-large.jpg"}/>
                                </div>)) : 'Ladataan...'}
                        </div>
                    </div>
                    <div className='row'>
                        {this.isFirstPage(this) ? ('') : (<button onClick={this.onClickCompaniesLeft}
                                                                  className="btn btn-custom">Edellinen</button>)}
                        {this.isLastPage(this) ? ('') : (<button onClick={this.onClickCompaniesRight}
                                                                 className="btn btn-custom">Seuraava
                        </button>)}

                    </div>
                </div>
            </div>
        </div>)
    }
}

export default Gallery;
