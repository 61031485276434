export const Image = ({item, thumbnailImage}) => {

    return (
        <div className='portfolio-item'>
            <div className='hover-bg'>
                {' '}
                <a
                    href={item.fields.website}
                    title={item.fields.name}
                    data-lightbox-gallery='gallery1'
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className='hover-text'>
                        <h4>{item.fields.name}</h4>
                    </div>
                    {item.fields.bannerLarge ?
                        <img src={'https:' + item.fields.bannerLarge.fields.file.url} className='img-responsive'
                             alt={item.fields.name}/>
                        : <img src={thumbnailImage} className='img-responsive' alt={item.fields.name}
                        />}

                </a>{' '}
            </div>
        </div>

    )
}
