import {Component} from "react";
import {
    createTheme, CssBaseline, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {Language, LocationOn, Phone} from "@mui/icons-material";

const tableTheme = createTheme({
    typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
        fontSize: 20
    }
});

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const contentful = require("contentful");
        const client = contentful.createClient({
            // This is the space ID. A space is like a project folder in Contentful terms
            space: "ri2478dp9tc5", // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
            accessToken: "-vmJagUp44O0Nn7iYzeJ6bqXsJiiA0G7WOXRBFZj2Ak"
        });

        client
            .getEntries({
                content_type: 'serviceType', order: 'fields.name'
            })
            .then(entries => {
                this.setState({
                    serviceTypes: entries.items
                })
            })
            .catch(err => console.log(err))
    }

    handleChange(event) {

    }

    handleSubmit(event) {
        event.preventDefault();

        let serviceTypeId = event.target[0].value;
        if (serviceTypeId === 'all') {
            serviceTypeId = null;
        }
        const searchTerm = event.target[1].value;

        let query = {
            content_type: 'company', order: 'fields.name'
        }

        if (serviceTypeId && serviceTypeId !== '') {
            query['fields.serviceType.sys.id'] = serviceTypeId;
        }
        if (searchTerm && searchTerm !== '') {
            query['query'] = searchTerm
        } else if (!serviceTypeId || serviceTypeId === '') {
            return;
        }

        this.setState({
            companies: [{
                sys: {
                    id: "#"
                }, fields: {
                    name: "Ladataan..."
                }
            }]
        })

        const contentful = require("contentful");
        const client = contentful.createClient({
            // This is the space ID. A space is like a project folder in Contentful terms
            space: "ri2478dp9tc5", // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
            accessToken: "-vmJagUp44O0Nn7iYzeJ6bqXsJiiA0G7WOXRBFZj2Ak"
        });
        client
            .getEntries(query)
            .then(entries => {
                if (entries && entries.items.length > 0) {
                    this.setState({
                        companies: entries.items
                    })
                } else {
                    this.setState({
                        companies: [{
                            sys: {
                                id: "#"
                            }, fields: {
                                name: "Ei hakutuloksia."
                            }
                        }]
                    })
                }
            })
            .catch(err => console.log(err))
    }

    render() {
        return (<div id='search'>
            <div className='container'>
                <div className='section-title text-center'>
                    <h2>Haku</h2>
                </div>
                <div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-4">
                                <select id="serviceType" name="serviceType" className="form-control">
                                    <option key="all" value="all">Kaikki toimialat</option>
                                    {this.state.serviceTypes ? this.state.serviceTypes.map((d) => (
                                        <option key={d.sys.id}
                                                value={d.sys.id}>{d.fields.name}</option>)) : '<option>Ladataan...</option>'}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <input type="text" id="searchTerm" name="searchTerm" placeholder="Kirjoita hakuehto (nimi, osoite, puhelinnumeron alkuosa yms.)" className="form-control"/>
                            </div>
                            <div className="col-md-1">
                                <button type="submit" className="btn btn-custom">Hae</button>
                            </div>
                        </div>
                    </form>

                    <div>
                        <ThemeProvider theme={tableTheme}>
                            <CssBaseline/>
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="Hakutulokset">
                                    <TableHead>
                                        {this.state.companies ? <TableRow>
                                            <TableCell>Nimi</TableCell>
                                            <TableCell><Phone/>&nbsp;Puh.</TableCell>
                                            <TableCell><LocationOn/>&nbsp;Osoite</TableCell>
                                            <TableCell>Kaupunki</TableCell>
                                            <TableCell><Language/>&nbsp;Internet-sivut</TableCell>
                                        </TableRow> : <TableRow></TableRow>}
                                    </TableHead>
                                    <TableBody>
                                        {this.state.companies ? this.state.companies.map((d) => (
                                            <TableRow key={d.sys.id}>
                                                {d.fields.tier < 4 ?
                                                    <TableCell sx={{fontWeight: "bold"}}>{d.fields.name}</TableCell> :
                                                    <TableCell>{d.fields.name}</TableCell>}
                                                <TableCell><a href={'tel:' + d.fields.phoneNumber}>{d.fields.phoneNumber}</a></TableCell>
                                                <TableCell><a target="_blank" rel="noreferrer"
                                                    href={'https://maps.google.com/?q=' + d.fields.streetAddress + ' ' + d.fields.city}>{d.fields.streetAddress}</a></TableCell>
                                                <TableCell>{d.fields.city}</TableCell>
                                                <TableCell><a target="_blank" rel="noreferrer"
                                                              href={d.fields.website}>{d.fields.website}</a></TableCell>
                                            </TableRow>)) : <TableRow>
                                            <TableCell>Ei hakutuloksia</TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default Search;
