import {Component} from "react";

class About extends Component {

    render() {
        return (
            <div id='services'>
                <div className='container'>
                    <div className='section-title text-center'>
                        <h2>Tietoja</h2>
                    </div>
                    <div className='text-center'>
                        <img src="img/web-logo-white.png" alt='Suonenjoen Palveluhakemisto 2022-2024'/>
                        <p>&nbsp;</p>
                        <p>
                            Web-sivuston toteutus ja ylläpito sekä painetun hakemiston toteutus&nbsp;
                            <a target="_blank" rel="noreferrer" href="https://www.lionsclubsuonenjokisoittu.fi">Lions
                                Club Suonenjoki / Soittu</a>. Yhteistyössä <a target="_blank" rel="noreferrer"
                                                                              href="https://www.suonenjoki.fi">Suonenjoen
                            Kaupunki</a>.
                        </p>
                    </div>
                    <div className='text-center'>
                        <p>
                            Haluatko lisätä yrityksesi hakemistoon? Onko tiedoissa virheitä? <a
                            href="mailto:palveluhakemisto-tuki@partagames.com">Ota yhteyttä!</a>
                        </p>
                    </div>
                    <div className='text-center'>
                        <p>
                            Tietosuoja: Sivusto ei tallenna käyttäjätietoja eikä käytä evästeitä.
                        </p>
                    </div>
                    <div className='text-center'>
                        <p>&nbsp;</p>
                        <a href='https://www.lionsclubsuonenjokisoittu.fi'><img src='img/lions-logo.png'
                                                                                alt='Lions Club Logo' width='128'
                                                                                height='128'/></a>
                    </div>
                </div>
            </div>
        )
    }

}

export default About;
