import Navigation from "./components/navigation";
import Gallery from "./components/gallery";
import Search from "./components/search";
import About from "./components/about";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const App = () => {

    return (
        <div>
            <Navigation/>
            <Gallery/>
            <Search/>
            <About/>
        </div>
    );
};

export default App;
